import React, { useState, useRef, useEffect } from 'react';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import HanchnetLogo from './images/HanchnetLogo';
import hanchbass from './images/hanchbass.png';
import './App.css';

// Modal Component
const Modal = ({ children, onClose }) => {
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <div className="modal-close" onClick={onClose}>&times;</div>
        {children}
      </div>
    </div>
  );
};

const Intro = () => (
  <div>
    <p className="justified-text">Hi there. My name is Josh Hanchar.</p>
    <p className="justified-text">New site currently in development, but in the meantime check out:</p>
  </div>
);

const Links = () => (
  <div id="main-links">
    <div className="main-link">
      <h2>ONLINE DRUM MACHINE</h2>
      <h3><a href="https://beats.hanch.net"><strong>BEATS.HANCH.NET</strong></a></h3>
    </div>
    <div className="main-link">
      <h2>VIDEOS</h2>
      <h3><a href="https://youtube.com/hanchstar"><strong>YOUTUBE CHANNEL</strong></a></h3>
    </div>
    <div className="main-link">
      <h2>SUPPORT THE CAUSE</h2>
      <h3><a href="https://store.hanch.net"><strong>BUY HANCH STUFF</strong></a></h3>
    </div>
  </div>
);

const MailchimpForm = () => (
  <div>
    <h2>JOIN THE CLUB!</h2>
    <div id="mc_embed_signup">
      <form action="https://hanch.us1.list-manage.com/subscribe/post?u=7dca6e5e64d6e5ccbaf915527&amp;id=0511e05a0a"
        method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" noValidate>
        <div id="mc_embed_signup_scroll">
          <div className="mc-field-group">
            <input type="text" placeholder="Name" name="NAME" className="required" id="mce-NAME" />
          </div>
          <div className="mc-field-group">
            <input type="email" placeholder="Email" name="EMAIL" className="required email" id="mce-EMAIL" />
          </div>
          <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
            <input
              type="text"
              name="b_7dca6e5e64d6e5ccbaf915527_0511e05a0a"
              tabIndex="-1"
              defaultValue=""
            />
          </div>
          <div className="clear">
            <input type="submit" value="Stay informed" name="subscribe" id="mc-embedded-subscribe" className="button" />
          </div>
        </div>
      </form>
    </div>
  </div>
);

// ContactForm Component using reCAPTCHA v3
const ContactForm = ({ onSubmit }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (!executeRecaptcha) {
      setError('reCAPTCHA not yet available');
      return;
    }

    try {
      // Execute reCAPTCHA with an action
      const token = await executeRecaptcha('contact_form_submit');

      // Append the token to the form data
      const submissionData = {
        ...formData,
        'g-recaptcha-response': token,
      };

      // Implement form submission logic here
      // For example, sending data to your backend
      const response = await fetch('https://your-backend-endpoint.com/sendEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(submissionData),
      });

      if (response.ok) {
        console.log('Form submitted successfully');
        onSubmit(); // Close the modal or provide feedback
      } else {
        console.error('Form submission failed');
        setError('Failed to submit the form. Please try again.');
      }
    } catch (err) {
      console.error('reCAPTCHA error:', err);
      setError('An error occurred with reCAPTCHA. Please try again.');
    }
  };
  return (
    <div>
      <h2>CONTACT ME</h2>
      <form id="contact-form" onSubmit={handleFormSubmit} method="POST">
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Name"
          required
          value={formData.name}
          onChange={handleChange}
        />
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Email"
          required
          value={formData.email}
          onChange={handleChange}
        />
        <select
          id="subject"
          name="subject"
          required
          value={formData.subject}
          onChange={handleChange}
        >
          <option value="" disabled>Reason</option>
          <option value="Private Lessons">I want to take private lessons with you</option>
          <option value="Hire Talent">I want to hire your talents for my project</option>
          <option value="Hire Band">I want to hire your band for my event/venue</option>
          <option value="Site Comment">Just want to comment on your site / other</option>
        </select>
        <textarea
          id="message"
          name="message"
          rows="10"
          placeholder="Message"
          required
          value={formData.message}
          onChange={handleChange}
        ></textarea>
        {error && <p className="error-message">{error}</p>}
        <button type="submit">Send</button>
      </form>
    </div>
  );
};

const Footer = ({ openModal }) => (
  <div className="page-footer">
    <p className="footer">
      This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </p>
    <p className="footer">
      &copy; {new Date().getFullYear()} Josh Hanchar. All Rights Reserved. <a href="#!" onClick={openModal} className="contact-link">Contact</a>
    </p>
    <p className="footer">
    </p>
  </div>
);

const AppContent = () => {
  // Define refs within the component
  const appRef = useRef(null);
  const hanchbassRef = useRef(null);

  // Define state and other hooks
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hanchbassLeft, setHanchbassLeft] = useState(0);

  // Function to handle form submission
  const handleSubmit = () => {
    setIsModalOpen(false);
  };

  // Function to open modal
  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  // Function to close modal
  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = 'auto';
  };

  // Function to calculate position
  const calculatePosition = () => {
    if (appRef.current && hanchbassRef.current) {
      const appRect = appRef.current.getBoundingClientRect();
      const imageWidth = hanchbassRef.current.offsetWidth;
      let newLeft = appRect.left - imageWidth / 4;

      // Calculate minimum and maximum left positions
      const minLeft = imageWidth / 6;
      const maxLeft = window.innerWidth - imageWidth / 6;

      // Clamp the newLeft value within the minLeft and maxLeft
      newLeft = Math.max(newLeft, minLeft);
      newLeft = Math.min(newLeft, maxLeft);

      setHanchbassLeft(newLeft);
    }
  };

  // Effect to calculate position on mount and resize
  useEffect(() => {
    // Calculate position on mount
    calculatePosition();

    // Recalculate on window resize
    window.addEventListener('resize', calculatePosition);
    return () => window.removeEventListener('resize', calculatePosition);
  }, []);

  useEffect(() => {
  const handleResize = () => {
    const width = window.innerWidth;
    const gradientOverlay = document.getElementById('gradient-overlay');

    if (width > 1000 && gradientOverlay) {
      // Calculate the opacity based on the window width
      const opacity = Math.min((width - 1000) / 400, 1); // Clamps between 0 and 1
      gradientOverlay.style.opacity = opacity;
    } else if (gradientOverlay) {
      gradientOverlay.style.opacity = 0;
    }
  };

  // Initial call and setup event listener
  handleResize();
  window.addEventListener('resize', handleResize);

  // Cleanup listener on unmount
  return () => window.removeEventListener('resize', handleResize);
}, []);


  return (
    <div className="page" ref={appRef}>
      <div id="fixed-background" />
      <div id="gradient-overlay" />
      <img
        id="hanchbass"
        ref={hanchbassRef}
        src={hanchbass}
        alt="Hanch playing bass"
        onLoad={calculatePosition} // Recalculate once image is loaded
        style={{
          position: 'fixed',
          bottom: '0',
          left: `${hanchbassLeft}px`,
          transform: 'translateX(-50%)',
          /* Add any additional styles as required */
        }}
      />
      <div>
        <header className="home-header">
          <div id="hanchheader">
            <div id="hanchlogo"><HanchnetLogo /></div>
            <h1>WELCOME TO HANCH.NET</h1>
          </div>
        </header>
        <main>
          <Intro />
          <Links />
          <MailchimpForm />
        </main>
      </div>
      <footer>
        <Footer openModal={openModal} />
      </footer>
      {/* Render Modal if isModalOpen is true */}
      {isModalOpen && (
        <Modal onClose={closeModal}>
          <ContactForm onSubmit={handleSubmit} />
        </Modal>
      )}
    <div className="background" />
    </div>
  );
};


const App = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LcBs_kpAAAAAO0zVFr0gzMs8T2BqrErW8mBd4td"
      useRecaptchaNet={true}
      language="en"
    >
      <AppContent />
    </GoogleReCaptchaProvider>
  );
};

export default App;